import React from 'react'
import appStoreImg from '../../images/app/downloadfromappstore@2x.png'
import playStoreImg from '../../images/app/downloadfromplaystore@2x.png'

export const appStoreLink = process.env.GATSBY_APP_STORE_URL
export const playStoreLink = process.env.GATSBY_PLAY_STORE_URL

const StoreButton = ({ os = 'ios', className = undefined }: Props) => {
  const isIos = os === 'ios'

  const link = isIos ? appStoreLink : playStoreLink

  return link ? (
    <a
      href={link}
      className={`w-64 ${className && className}`}
      target="__blank"
    >
      <img
        src={isIos ? appStoreImg : playStoreImg}
        alt={`Download Goodpath from the ${isIos ? 'app' : 'play'} store`}
      />
    </a>
  ) : null
}

export default StoreButton

type Props = {
  os?: 'ios' | 'android'
  className: string | undefined
}
