import React, { useEffect, useState, useCallback, useMemo } from 'react'
import classNames from 'classnames'
import formatString from 'format-string-by-pattern'
import SimpleHeader from '../components/Layout/SimpleHeader'
import Button from '../components/common/Button'
import PrivateRoute from '../components/PrivateRoute'
import { useCountryContext } from '../components/common/CountryProvider'
import { useUserContext } from '../contexts/User/userContext'
import { useGoodpatherCheck } from '../utils/customHooks'
import { DownloadFromAppStoreButton } from '../components/App/DownloadFromAppStoreButton'
import { trackUserAction } from '../utils/analytics-tracking'
import { NoIndex } from '../components/NoIndex'
import phonesImage from '../images/appDownload/Phones.png'
import check from '../images/SharpCheckX4.png'
import {
  getApiPhoneNumber,
  getDisplayPhoneNumber,
  getPhonePlaceHolderAndMask
} from '../components/Checkout/checkoutHelper'
import DownloadAppWithMobileSupport from '../components/App/DownloadAppWithMobileSupport'
import { deepLinkTo } from '../utils/helpers'
import { graphql, useStaticQuery } from 'gatsby'
import { useLocalizedContent } from '../utils/localization/useLocalizedContent'

const translationsKeys = {
  clickResent: 'clickResent',
  messageSent: 'messageSent',
  sentDownloadLink: 'sentDownloadLink',
  appDownloadSub3: 'appDownloadSub3',
  appDownloadSub2: 'appDownloadSub2',
  appDownloadSub1: 'appDownloadSub1',
  accessYourProgram: 'accessYourProgram',
  resendLink: 'resendLink'
}

const AppDownload = ({ location, deeplink = false }) => {
  const [linkSent, setLinkSent] = useState(false)
  const [visualPhone, setVisualPhone] = useState('')
  const [userPhone, setUserPhone] = useState('')
  const { user } = useUserContext()
  const { currentCountry } = useCountryContext()

  const data = useStaticQuery(query)
  const { translations } = useLocalizedContent(data)

  // Don't require the user to put in the country code if it's +1. We'll add it implicitly on form submission.
  const { phonePlaceholder, phoneMask } = useMemo(
    () => getPhonePlaceHolderAndMask(currentCountry),
    [currentCountry]
  )

  useGoodpatherCheck(user.email)

  useEffect(() => {
    const displayPhoneNumber = getDisplayPhoneNumber(
      currentCountry,
      user?.phoneNumber
    )
    const phoneNumber = formatString(phoneMask, displayPhoneNumber)
    const apiPhoneNumber = getApiPhoneNumber(currentCountry, phoneNumber)
    setVisualPhone(phoneNumber)
    setUserPhone(apiPhoneNumber)
  }, [currentCountry, phoneMask, user?.phoneNumber])

  useEffect(() => {
    if (deeplink) {
      deepLinkTo(deeplink)
    }
  }, [deeplink])

  const handleAppDownloadTextMessageClick = useCallback(() => {
    trackUserAction('Send App Links', {
      phone: userPhone
    })
    setLinkSent(true)
  }, [userPhone])

  const onPhoneChange = useCallback(
    (event) => {
      // TODO: This could use the pattern prop in `Input` just like is done in `CustomerFields.js`. Change later...
      const phoneNumber = formatString(phoneMask, event.target.value)
      const apiPhoneNumber = getApiPhoneNumber(currentCountry, phoneNumber)
      setVisualPhone(phoneNumber)
      setUserPhone(apiPhoneNumber)
    },
    [currentCountry, phoneMask]
  )

  const subtitleContent = useMemo(
    () =>
      [
        translations[translationsKeys.appDownloadSub1],
        translations[translationsKeys.appDownloadSub2],
        translations[translationsKeys.appDownloadSub3]
      ],
    [translations]
  )

  return (
    <PrivateRoute location={location}>
      <NoIndex />
      <div className="flex flex-col h-screen bg-gp-off-white sofia">
        <SimpleHeader backButton={false} />
        <div className="flex flex-wrap items-center justify-center h-auto md:h-screen">
          <div className="m-6 text-center md:hidden">
            <span className="text-3xl leading-tight bold text-gp-violet">
              {translations[translationsKeys.accessYourProgram]}
            </span>
          </div>
          <div className="items-center block w-full h-auto pt-10 m-4 mb-20 rounded-lg md:w-1/2 md:rounded-0 md:m-0 bg-gp-violet-5 md:py-0 md:h-full md:flex">
            <div className="flex flex-col max-w-2xl px-0 mx-auto md:px-6">
              <ul className="w-full px-1 mt-4 mb-0 text-lg md:mb-10">
                {subtitleContent?.map((item) => (
                  <li
                    className="flex flex-row mt-0 mb-1 md:justify-center"
                    key={item}
                  >
                    <span>
                      <img
                        className="flex-none w-6 h-full"
                        src={check}
                        alt="A check icon that indicates an item in a list"
                      />
                    </span>
                    <span
                      className="flex items-center ml-1 text-brand-purple"
                    >
                      {item}
                    </span>
                  </li>
                ))}
              </ul>
              <div className="flex flex-row items-center justify-center w-10/12 mx-auto overflow-hidden md:overflow-none md:h-full md:w-full short:w-8/12 sd:w-8/12">
                <img
                  className="relative w-full top-2 md:top-0 md:w-2/3"
                  src={phonesImage}
                  alt="Two screens showcasing the Goodpath app and how you can log in and track your personal program."
                />
              </div>
              <div className="flex-row justify-center hidden w-full h-10 my-10 md:flex">
                <DownloadFromAppStoreButton
                  className="flex flex-row items-center justify-center w-2/3 py-8 "
                  orientation="horizontal"
                />
              </div>
            </div>
          </div>

          <div
            className={classNames([
              'fixed md:hidden bottom-0 bg-white w-full flex flex-row justify-center',
              'box-shadow: 0px -1px 0px rgb(225 223 233 / 25%), 0px 3.08047px 30.8047px rgb(0 0 0 / 8%)'
            ])}
          >
            <DownloadAppWithMobileSupport />
          </div>

          <div className="hidden md:block md:w-1/2">
            <div className="flex flex-col justify-center max-w-2xl px-6 mx-auto items-left">
              <span className="mb-6 text-5xl leading-tight bold text-gp-violet">
                {translations[translationsKeys.accessYourProgram]}
              </span>
              <span className="my-2 text-xl leading-tight justify-left">
                {translations[translationsKeys.sentDownloadLink]}
              </span>
              <div className="flex flex-col">
                <div className="mb-8">
                  {/* <span className="block text-xl bold">Phone Number</span> */}
                  <input
                    className="w-1/2 h-10 px-4 py-6 my-4 border rounded-lg outline-none border-gp-grey-40"
                    name="telephone"
                    type="tel"
                    placeholder={phonePlaceholder}
                    value={visualPhone}
                    onChange={onPhoneChange}
                    disabled={true}
                  />
                </div>
                <div className="rounded-full w-52r">
                  <Button
                    variant="primary"
                    type="button"
                    onClick={handleAppDownloadTextMessageClick}
                    disabled={linkSent}
                  >
                    {translations[translationsKeys.resendLink]}
                  </Button>
                </div>
                {linkSent ? (
                  <div className="mt-5">
                    <span className="leading-snug text-md">
                      {translations[translationsKeys.messageSent]}
                    </span>
                  </div>
                ) : (
                  <div className="mt-5">
                    <span className="text-sm leading-snug">
                      {translations[translationsKeys.clickResent]}
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </PrivateRoute>
  )
}

export default AppDownload

const query = graphql`
  query AppDownloadQuery {
    allContentfulContentGroup(
      filter: { readableId: { eq: "app-download" } }
    ) {
      nodes {
        ...ContentGroupFragment
      }
    }
  }
`
