import React, { useCallback } from 'react'
import { isAndroid, isIOS, isMobile } from 'react-device-detect'
import { DownloadFromAppStoreButton } from './DownloadFromAppStoreButton'
import Button from '../common/Button'
import { appStoreLink, playStoreLink } from './StoreButton'

const DownloadAppWithMobileSupport = () => {
  const onDownload = useCallback(() => {
    if (isIOS && appStoreLink) {
      // check if we have the app installed first
      window.location.href = appStoreLink
      return
    } else if (isAndroid && playStoreLink) {
      // check if we have the app installed first
      window.location.href = playStoreLink
      return
    }
    return false
  }, [])

  return (
    <>
      {isMobile && (isIOS || isAndroid) ? (
        <div className="bg-white py-2">
          <Button
            onClick={onDownload}
            size="large"
            type="download"
            disabled={false}
            icon={false}
            ariaLabel={false}
            isLoading={false}
            isBlock={false}
            isBlockMobile={false}
          >
            Download the App
          </Button>
        </div>
      ) : (
        <DownloadFromAppStoreButton
          className="w-10/12 py-8 flex flex-row items-center justify-center"
          orientation="horizontal"
        />
      )}
    </>
  )
}

export default DownloadAppWithMobileSupport
