import React from 'react'
import StoreButton from './StoreButton'

export const DownloadFromAppStoreButton = ({
  className,
  orientation = 'horizontal'
}) => {
  const iosWrapClassName =
    orientation === 'horizontal' ? 'px-2 w-full short:w-7/12' : ''
  const androidWrapClassName =
    orientation === 'horizontal' ? 'px-2 w-full short:w-7/12' : 'pt-8'
  const androidClassName = orientation === 'vertical' ? 'pt-8' : ''

  return (
    <div className={className}>
      <div className={iosWrapClassName}>
        <StoreButton os="ios" />
      </div>
      <div className={androidWrapClassName}>
        <StoreButton os="android" className={androidClassName} />
      </div>
    </div>
  )
}
